@import "src/whitelabel/variables";

.sidebar {
  background-color: #fff;
  border-right: 1px solid $border-color-dark;
  min-width: $sidebar-width;
  height: 100vh;

  &__inner {
    min-width: $sidebar-width;
  }

  &.hide {
    overflow: hidden;
    min-width: 0;
    max-width: 0;
  }
}

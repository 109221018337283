// dimensions
$border-radius: 6px;
$sidebar-width: 250px;
$header-height: 60px;
$spacer: .7rem;

// colors
$app-color-1: #41b049;
$app-color-2: #025481;
$primary: #657fa0;
$light: #f5f6fa;
$link-color: $primary;
$border-color: rgba(209, 211, 222, 0.3);
$border-color-dark: #d1d3de;
$hover-color: $app-color-1;
$body-color: $primary;
$gray-600: #657fa0;
$cyan: #1f8cc7;

// buttons
$success: $app-color-1;

// Typography
$headings-color:#3d5879;
$font-size-base: 1.00rem;
$font-family-sans-serif: Roboto, sans-serif;

// Links
$link-hover-decoration: none;
$link-hover-color: $app-color-1;

// forms
$input-padding-y: .9rem;
$input-padding-x: 1.2rem;
$input-focus-box-shadow: unset;
$input-focus-border-color: #7a8da3;

// Cards
// $card-border-radius: 6px;
$card-cap-bg: transparent;
$card-spacer-y: 1rem;
// $card-spacer-x:

// Breadcrumbs
$breadcrumb-padding-y: 1.5rem;
$breadcrumb-padding-x: 1.625rem;
$breadcrumb-color: #fff;
// $breadcrumb-item-padding:           .5rem !default;

// $breadcrumb-margin-bottom:          1rem !default;

$breadcrumb-bg: $app-color-2;
$breadcrumb-divider-color: #fff;
$breadcrumb-active-color: #fff;
// $breadcrumb-divider:                quote("/") !default;

// $breadcrumb-border-radius: $card-border-radius;

// dropdown
$dropdown-border-width: 0;

// cards
$box-shadow: 0 .5rem 1rem rgba($primary, .15);

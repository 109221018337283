@import "src/whitelabel/variables";

$size: 28px;

.user-info {
  &__pic {
    border-radius: $size;
    height: $size;
    width: $size;
    overflow: hidden;
  }
}

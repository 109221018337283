@import "src/whitelabel/variables";

.dropdown-menu {
  overflow: auto;
  max-height: 500px;
}

.navitem {
  position: relative;
  display: inline-block;
  color: #8499b3;
  cursor: pointer;

  svg {
    fill: currentColor;
    transition: all .1s;
  }

  &__icon {
    border-bottom: 3px solid transparent;
    padding: $spacer * 1.3 24px;
    margin: 0 8px;
  }

  &:hover &__icon, &.active &__icon{
    border-bottom-color: $hover-color;
  }

  &:hover &__icon svg, &.active &__icon svg {
    color: $hover-color;
    fill: currentColor;
  }

  &__dropdown {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    background-color: #fff;
    box-shadow: rgba(101, 127, 169, 0.3) 0px 0px 48px -15px;
    border-radius: 12px;
    top: $header-height;
    transition: all .1s;
    z-index: 100;

    .navitem {
      transform: scale(.9);
    }

    &.active {
      top: $header-height - 7;
      opacity: 1;
      pointer-events: all;
    }

    .active, a:hover {
      color: $hover-color;
      fill: currentColor;
    }
  }
}

.active .navitem {
  &__icon {
    border-bottom: 3px solid $hover-color;
  }

  svg {
    color: $hover-color;
    fill: currentColor;
  }
}

.outlayer {
  cursor: default;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 50;
}

@import "./whitelabel/variables";

h6 {
  &.bottom-green {
    position: relative;
    padding-bottom: $spacer * 1.8;

    &::after {
      position: absolute;
      content: '';
      background-color: $app-color-1;
      height: 2px;
      width: 45px;
      right: calc(50% - (45px / 2));
      bottom: 0;
    }
  }
}

.show-active {
  display: none;
}

a.active {
  color: $app-color-1;

  .show-active {
    display: block;
  }

  .hide-active {
    display: none;
  }
}

// Breadcrumb

.breadcrumb {
  font-size: 1.125rem;
  font-weight: 300;
  color: $breadcrumb-color;
  font-family: $font-family-sans-serif;

  &:before {
    content: '';
    display: block;
    width: 3px;
    height: 25px;
    background: #56B95D;
    position: absolute;
    left: 0;
  }

  .active {
    font-weight: 500;
  }
}

// .End Breadcrumb

.dropdown-menu {
  box-shadow: $box-shadow;

  ul {
    padding: 2px 0;
    margin: 0;

    li {
      list-style: none;
      padding: 2px 8px;
      cursor: pointer;
    }
  }
}

mark, .mark {
  padding: 0;
}

// Links

a.green {
  color: $app-color-1;
}

a.active {
  svg {
    fill: currentColor;
    transition: all .1s;
  }
}

// cards
.card {
  border: none !important;

  &.full-height {
    min-height: 100%;
    max-height: calc(100vh - 212px);
    overflow-y: auto;
  }
}

.card-title {
  font-weight: 600;
  position: relative;

  &.dashed-green::before {
    content: '';
    position: absolute;
    background-color: $app-color-1;
    top: -2px;
    bottom: -2px;
    left: -19px;
    width: 3px;
  }
}

.card-footer {
  padding: $spacer 0 0 !important;
}

// helpers

.border-radius {
  border-radius: $border-radius;
}

.overflow-hidden {
  overflow: hidden !important;
}

.no-borders {
  border: none !important;
}

.no-outline {
  outline: none !important;
}

.font-80 {
  font-size: 80%;
}

.cursor-pointer {
  cursor: pointer;
}

.disabled {
  display: block;
  opacity: .5;
  pointer-events: none;
  user-select: none;
}

.green-text {
  color: $app-color-1 !important;
}

.border-dashed {
  border-style: dashed;
}

// tables
.MuiTableCell-body, .MuiTableCell-head, .MuiTablePagination-root {
  color: $primary !important;
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignLeft {
  word-break: break-all;
  time {
    word-break: normal;
    white-space: nowrap;
  }
}

// forms
legend {
  font-size: 1rem !important;
  font-weight: 700;
}

.pointer-cursor {
  cursor: pointer;
}

.MuiTableCell-root {
  z-index: 0 !important;
}

.active.card {
  border: 1px solid $app-color-1 !important;
}

.active {
  .btn-secondary, .btn-secondary:hover {
    background-color: $app-color-1;
    border-color: $app-color-1;
  }
}

.primary-color {
  color: $primary !important;
}

.text-bold {
  font-weight: bold !important;
}

.form-control {
  color: $primary !important;
}

// tabs

.react-tabs {
  &__tab-list {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid $border-color-dark;
    padding: 0;
    margin: 0;
  }

  &__tab {
    border-radius: 0;
    border: none;
    padding: 8px 16px;

    &--selected {
      border: none;
      border-bottom: 2px solid $app-color-1;
      color: $app-color-1;
    }
  }
}

// collapsible

div {
  .Collapsible {
    border-bottom: 1px solid $border-color-dark;

    div {
      word-break: break-all;
    }

    &:last-child {
      border-bottom: none;
    }

    &__trigger {
      cursor: pointer;

      .icon {
        fill: $primary;
        opacity: .6;
        transition: all .15s;
      }

      &.is-open {
        .icon {
          opacity: 1;
          transform: rotate(180deg);
        }
      }
    }

    &__contentOuter {
      transition: height .1s linear 0s !important;
    }
  }
}

// Date Picker

.date-picker-borderless {
  .react-date-picker {
    &__wrapper {
      border: none;
    }
  }
}

.date-picker-custom {
  background: $app-color-2;
  padding: ($spacer * .6) $spacer;
  border-radius: $border-radius;

  .react-date-picker {
    &__wrapper {
      border: none;
    }
  }

  .react-date-picker__inputGroup__input {
    color: #fff;
    &::placeholder {
      color: transparent;
    }
  }

  .react-date-picker__button svg g {
    stroke: none !important;
  }
}

// inputs

.no-focus {
  &:focus {
    outline: none;
  }
}

//
.item-hover {
  &:hover, &.active {
    background-color: rgba(0, 0, 0, 0.07);
  }
}

.child-svg-color-1 svg {
  fill: $app-color-1;
}

.bg-color-1 {
  background-color: $app-color-1;
}

.bg-color-2 {
  background-color: $app-color-2;
}

.bg-color-3 {
  background-color: darken($app-color-2, 5%);
}

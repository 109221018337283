@import '../node_modules/react-tabs/style/react-tabs.scss';
@import "./whitelabel/variables";
@import "./bootstrap-overrides.scss";
// Required
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";
// Optional
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/code";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/card";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/input-group";
@import "../node_modules/bootstrap/scss/breadcrumb";
@import "../node_modules/bootstrap/scss/dropdown";
@import "../node_modules/bootstrap/scss/alert";
@import "../node_modules/bootstrap/scss/tables";

html, body, #root {
  height: 100%;
}

// Critical

.custom-dropdown {
  & > :first-child:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;

    border-top: 8px solid #56B95D;
    position: absolute;
    top: 45%;
    right: 10px;
  }
}

// icon eye-icon
.eye-icon {
  right: 20px;
  top: 13px;
  position: absolute;
}

// Cron
.cron-block-time, .cron-block-mins {
  display: none;
}

// Table
tbody tr.MuiTableRow-root:hover {
  background: #FAFAFC;
}

// .end Table

// Date Range
.react-date-picker__calendar .react-calendar {
  border: 0;
  border-radius: 6px;
  box-shadow: 0 17px 24px 0 #c4c8d1;
  margin-top: 8px;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,
button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  display: none;
}

button.react-calendar__navigation__label {
  order: -1;
  text-transform: uppercase;
  color: #1D2E44;
  font-weight: 700;
  position: relative;
  padding-left: 10%;
  text-align: left;
}

button.react-calendar__navigation__label:after {
  content: '';
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;

  border-top: 8px solid #56B95D;
  position: absolute;
  top: 18px;
  right: 45%;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button,
button.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
  color: #41B049;
  font-size: 26px;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button[disabled],
button.react-calendar__navigation__arrow.react-calendar__navigation__next-button[disabled] {
  cursor: not-allowed;
}

button.react-calendar__tile.react-calendar__month-view__days__day:hover {
  border-radius: 50px;
}

.react-calendar__month-view__weekdays__weekday {
  color: #657FA0;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}

.react-calendar__month-view__weekdays__weekday abbr:hover {
  cursor: default;
}

.react-calendar__month-view__weekdays {
  border-bottom: 2px solid #C4C8D1;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

button.react-calendar__tile.react-calendar__month-view__days__day {
  color: #3D5879;
  border: 1px solid transparent;
}

button.react-calendar__tile.react-calendar__month-view__days__day:hover {
  border: 1px solid #41B049;
  color: #41B049;
  background-color: #fff;
  font-weight: bolder;
}

button.react-calendar__tile.react-calendar__month-view__days__day[disabled] {
  cursor: not-allowed;
}

button.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
  background-color: #fff;
  border-color: #41B049;
  border-radius: 50px;
  color: #41B049;
}

.react-calendar__month-view__days,
.react-calendar__month-view__weekdays {
  padding: 0 10px;
}

button.react-calendar__tile--hasActive {
  background: #41B049;
  color: #fff;
}

button.react-calendar__tile--hasActive:enabled:hover,
button.react-calendar__tile--hasActive:enabled:focus {
  background: #41B049;
  opacity: .8;
}
// .end Date Range

// Arrow down detail
.arrow-down-detail {
  position: relative;

  &:after {
    content: '';
    width: 10px;
    height: 10px;
    background: white;
    position: absolute;
    bottom: -5px;
    transform: rotate(45deg);
    border-width: 0 1px 1px 0;
    border-style: solid;
    border-color: #f1f2f5;
  }
}

// .end Arrow down detail

// Forgot Password
.floating-back-btn {
  background: #fff;
  position: absolute;
  left: -22px;
  padding: 10px;
  border-radius: 22px;
  box-shadow: 0 7px 9px 0 rgba(57, 88, 170, 0.21);
}

// .end Forgot Password

// Modal Info Table
.quality-info {
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  padding-right: 1px;

  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;

    thead {
      tr {
        background: #025481;

        td {
          color: $white;
          text-align: center;
          font-weight: bold;
          font-size: 13px;
          padding: 15px;
          width: 50%;

          &:first-child {
            border-right: 1px solid #DCE1E4;
          }
        }
      }
    }

    tbody {
      td {
        padding: 5px;
        font-size: 14px;
        border: 1px solid #DCE1E4;
        text-align: center;
        color: #657FA0;
      }
    }
  }
}

// .end Modal Info Table

// Custom filter
.custom-filter {
  position: relative;
  padding: 0 20px 0 40px;

  .arrow-right-detail {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    border-color: #ffffff2b;
    border-style: solid;
    border-width: 1px 1px 0 0;
    width: 25px;
    height: 25px;
    margin: 0 0 0 -40px;
    -webkit-transform: rotate(45deg) skew(20deg, 20deg);
  }

  .input-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-right: 1px solid #ffffff2b;
    flex: 1;
    margin: 0 10px;

    input {
      background: transparent;
      border: none;
      color: #fff;
      outline: none;
      flex: 1;
      padding-right: 20px;
    }

    .input-icon {
      margin-right: 8px;
    }

    input::placeholder {
      color: #fff;
      font-weight: 500;
    }
  }

  /* The container */
  .radio-container {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-left: 10px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 0;
  }

  /* Hide the browser's default radio button */
  .radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #eee;
    border-radius: 50%;
  }

  /* On mouse-over, add a grey background color */
  .radio-container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the radio button is checked, add a blue background */
  .radio-container input:checked ~ .checkmark {
    background-color: white;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  .radio-container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .radio-container .checkmark:after {
    top: 5px;
    left: 5px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #41B049;
  }
}

// .end Custom filter

// Notification box
.notification-wrapper {
  position: relative;

  .notification-box {
    position: absolute;
    background-color: white;
    display: flex;
    flex-direction: row;
    top: 30%;
    width: 50%;
    min-width: 276px;
    margin: auto;
    left: 0;
    right: 0;
    padding: 14px;
    border-radius: 6px;
    box-shadow: 0 6px 30px 0 rgba(101, 127, 160, 0.09);
    align-items: center;

    .notification-icon-wrapper {
      background: #1F8CC7;
      border-radius: 50%;
      width: 34px;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 12px;
    }

    .notification-info-wrapper {
      max-width: 75%;
      display: flex;
      flex-direction: column;
      text-align: left;

      .notification-title {
        font-weight: bold;
        font-size: 15px;
        color: #3D5879;
      }

      .notification-description {
        font-size: 14px;
        color: #657FA0;
      }
    }
  }
}

// .end Notification box

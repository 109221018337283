@import "src/whitelabel/variables";

.header {
  height: $header-height;
  background-color: #fff;
  border-bottom: 1px solid $border-color-dark;

  &__logo {
    border-right: 1px solid $border-color-dark;
    height: $header-height;
    min-width: $sidebar-width;

    img {
      height: calc(#{$header-height} * .8);
    }
  }
}
